import { lazy } from 'react';

export const DialogMapper = {
  'comment-dialog': lazy(() => import('./dialogs/CommentDialog')),
  'change-license-dialog': lazy(() => import('./dialogs/ChangeLicenseDialog')),
  'configure-edc-connection-dialog': lazy(() => import('./dialogs/ConfigureEDCConnectionDialog')),
  'generate-edc-configuration-dialog': lazy(
    () => import('./dialogs/GenerateEDCConfigurationDialog'),
  ),
  'remove-product-company-dialog': lazy(() => import('./dialogs/RemoveProductCompanyDialog')),
  'answer-pcf-dialog': lazy(() => import('./dialogs/AnswerPcfDialog')),
  'get-started-dialog': lazy(() => import('./dialogs/GetStartedDialog')),
};

export type DialogType = keyof typeof DialogMapper;
