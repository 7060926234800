import FailedToGet from '@components/failed-to-get/FailedToGet';
import useDialogContext from '@hooks/useDialogContext';
import CompanyService from '@services/CompanyService';
import { EdcConfigurationStatus, ICompany } from '@shared/interfaces/ICompany';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import ChangeLicenseButton from './components/change-license/ChangeLicenseButton';
import CompaniesTableHeader from './components/companies-table-header/CompaniesTableHeader';
import ConfigureEDCConnectionButton from './components/configure-edc-connection/ConfigureEDCConnectionButton';
import GenerateEDCConfigurationButton from './components/generate-edc-configuration/GenerateEDCConfigurationButton';

const CompaniesTable = (): JSX.Element => {
  const companyService = new CompanyService();

  const { openDialog } = useDialogContext();

  // Dialog visibility handlers (show)
  const onGenerateEDCConfigurationButtonClick = (company: ICompany): void =>
    openDialog('generate-edc-configuration-dialog', { company });

  const onConfigureConnectionConfigurationButtonClick = (company: ICompany): void => {
    openDialog('configure-edc-connection-dialog', { company });
  };

  // Dialog visibility handlers (hide)

  const edcConfigurationTemplate = (data: ICompany): JSX.Element => {
    if (data.edc_configuration_status !== EdcConfigurationStatus.Pending) {
      return (
        <ConfigureEDCConnectionButton
          data={data}
          onClick={(): void => onConfigureConnectionConfigurationButtonClick(data)}
        />
      );
    } else {
      return (
        <GenerateEDCConfigurationButton
          data={data}
          onClick={(): void => onGenerateEDCConfigurationButtonClick(data)}
        />
      );
    }
  };

  const licenseTemplate = (data: ICompany): JSX.Element => (
    <ChangeLicenseButton
      license={data.license}
      onClick={(): void => openDialog('change-license-dialog', { company: data })}
    />
  );

  // RQ Query to get companies
  const {
    isLoading: isGetCompaniesLoading,
    isFetching: isGetCompaniesFetching,
    isError: isGetCompaniesError,
    error: getCompaniesError,
    data: companies,
    refetch: refetchCompanies,
  } = companyService.listCompanies();

  if (isGetCompaniesError) {
    return <FailedToGet label='companies' reason={getCompaniesError.message} />;
  }

  return (
    <div className='w-full'>
      <DataTable
        value={companies}
        sortField='company_id'
        sortOrder={1}
        header={
          <CompaniesTableHeader refetch={refetchCompanies} isFetching={isGetCompaniesFetching} />
        }
        loading={isGetCompaniesLoading}
      >
        <Column field='bpn' sortable header='BPN' />
        <Column field='company_id' sortable header='Company ID' className='w-2' />
        <Column field='company_name' sortable header='Company Name' />
        <Column field='license' sortable header='License' body={licenseTemplate} className='w-2' />
        <Column align='right' body={edcConfigurationTemplate} />
      </DataTable>
    </div>
  );
};

export default CompaniesTable;
